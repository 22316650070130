import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css';
import 'swiper/css/autoplay';
import gc from '../api/Gift_card'
import "slick-carousel/slick/slick-theme.scss";


function GiftCards() {

  const [responseGet, responseData] = useState([]);

  useEffect(() => {
    gc()
      .then(item => {
        responseData(item.brands);
      })
  }, [])


  return (
    <div className='w80 m-auto'>
      <div className='text-center h3 fw600 pt5 pb2'>Popular Giftcards</div>
      <Swiper
      slidesPerView={4}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      modules={[Autoplay]}
      breakpoints={{
        1024: {
          // width: 768,
          slidesPerView: 4.2,
        },
        768: {
          // width: 768,
          slidesPerView: 3.2,
        },
        300: {
          // width: 768,
          slidesPerView: 1.2,
        },
      }}
      className="mySwiper"
    > 
    
       {
responseGet.map((data,i) => (
            <SwiperSlide key={i} >
            <div className='slide--img--center' style={{ backgroundColor: data.color_code,marginRight:responseGet.length - 1 === i ? '15px' : ''}}><img src={data.brand_image_url} alt={data.brand_slug} className='slide--img' /></div>
            <p className='text-center fw400 pt1 pb3'>{data.brand_name}</p>
            </SwiperSlide>
        ))}
    </Swiper>
    </div>
  )
}

export default GiftCards
