import React from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAuth } from "../hooks/useAuth";
import Addi_veri from '../api/Additional_verification';
import PartnerDetails from '../api/PartnerDetails';
import Access_token from '../api/Access_token';
import 'react-toastify/dist/ReactToastify.css';


function RegistrationForm() {

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false
    });
  }

  const { login } = useAuth();

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur"
  });

  const onSubmit = (data, e) => {
    window.localStorage.setItem("email", data.email_address);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`
      },
      body: JSON.stringify({
        name: data.name,
        poc_name: data.user_name,
        email: data.email_address,
        poc_mobile: data.phone_number,
        address: data.address,
        gstn: data.gstno,
        pan_card_number: data.panno
      })
    };

    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/sign_up`, requestOptions)
      .then(function (response) {
        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(data => {
        if ('success' in data) {
          
          //additional verification
          Addi_veri(data.access_token);
          
          
          
          //login access token set
          login({
            access_token: `${data.access_token}`,
            refresh_token: `${data.refresh_token}`,
            expires_in: `${data.expires_in}`
          });

          

          let redirect_url = '/otpverification';
          let data_status = 'not_empty';

          //Partner Details saving
          PartnerDetails(data.access_token,redirect_url,data_status);
          
        } else {
          //error message
          errorsmsg(data.errors);
        }
      })
      .catch(error => {
        //error message
        errorsmsg(error);
      })
  };

  return (
    <div className='gap w100'>
      <form className='form regis__form--border_top' onSubmit={handleSubmit(onSubmit)}>
        <div className='registration__form--title' >Registration</div>
        <div>
          <div className='form-group'>
            <label htmlFor='name'>Company Name</label>
            <input type="text" name="name" {...register("name", {
              required: 'name is required',
              pattern: {
                value: /^[A-Za-z& ]+$/i,
                message: "invalid company name"
              },
              minLength: {
                value: 5,
                message: 'company name at list more then 5 alphabet'
              }
            })} placeholder="Company Name" className='form-control' />
            <p className='cdanger h6'>{errors.name && <span>{errors.name.message}</span>}</p>
          </div>
          <div className='form-group'>
            <label htmlFor='last_name'>User Name</label>
            <input type="text" name="user_name"  {...register("user_name",
              {
                required: 'user name is required',
                pattern: {
                  value: /^[A-Za-z ]+$/i,
                  message: "invalid user name"
                },
                minLength: {
                  value: 3,
                  message: 'user name at list more then 3 alphabet'
                }
              }
            )} placeholder="User Name" className='form-control' />
            <p className='cdanger h6'>{errors.user_name && <span>{errors.user_name.message}</span>}</p>
          </div>
          <div className='form-group'>
            <label htmlFor='email_address'>Email Address</label>
            <input type="email" name="email_address"
              {...register("email_address",
                {
                  required: 'email address is required',
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "invalid email address"
                  },
                  minLength: {
                    value: 3,
                    message: 'invalid email address'
                  }
                }
              )} placeholder="Email Address" className='form-control' />
            <p className='cdanger h6'>{errors.email_address && <span>{errors.email_address.message}</span>}</p>
          </div>
          <div className='form-group'>
            <label htmlFor='phone_number'>Phone Number</label>
            <input type="number"
              {...register("phone_number",
                {
                  required: 'phone number is required',
                  pattern: {
                    value: /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, // eslint-disable-line
                    message: "invalid mobile number"
                  }
                }
              )} name="phone_number" onWheel={ event => event.currentTarget.blur() } placeholder="Phone Number" className='form-control' />
            <p className='cdanger h6'>{errors.phone_number && <span>{errors.phone_number.message}</span>}</p>
          </div>
          <div className='form-group'>
            <label htmlFor='address'>Address</label>
            <textarea rows="10" name="address" {...register("address", {
              required: 'address is required',
              minLength: {
                value: 20,
                message: 'address at list more then 20 alphabet'
              }
            })} placeholder="Address" className='form-control' ></textarea>
            <p className='cdanger h6'>{errors.address && <span>{errors.address.message}</span>}</p>
          </div>
          <div className='form-group'>
            <label htmlFor='gstno'>GST Number</label>
            <input type="text" name="gstno" {...register("gstno", {
              required: 'GST number is required',
              pattern: {
                value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                message: "invalid GST number"
              }
            })} placeholder="GST Number" className='form-control' />
            <p className='cdanger h6'>{errors.gstno && <span>{errors.gstno.message}</span>}</p>
          </div>
          <div className='form-group'>
            <label htmlFor='gstno'>PAN Number</label>
            <input type="text" name="panno" {...register("panno", {
              required: 'PAN Number is required',
              pattern: {
                value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                message: "invalid PAN Number"
              }
            })} placeholder="PAN Number" className='form-control' />
            <p className='cdanger h6'>{errors.panno && <span>{errors.panno.message}</span>}</p>
          </div>
          <div className='d-flex w100'>
            <div className='w50  d-flex'>
              <input type="checkbox" name="agree" className='checkbox__declarations' {...register("agree", { required: true })} />
              <label htmlFor="agree" className='m0'>Declarations</label>
              
            </div>
            <div className='text-right w50'>
              <a href='https://www.zingoy.com/terms' target='_blank' rel="noreferrer" className='text-decoration-none regis_tc' >T&C Apply</a>
            </div>
          </div>
          <p className='cdanger h6'>{errors.agree?.type === 'required' && "Declarations is required"}</p>
          <div>
            <button type="submit" className='btn btn-pink btn__full--width' >REGISTER NOW</button>
          </div>
          <p className='text-center mt2 mb1 bold'>OR</p>
          <p className='text-center'>To Login Partner <a href='/'>click here</a></p>
        </div>
      </form>
    </div>
  )
}

export default RegistrationForm
