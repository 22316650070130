import Access_token from './Access_token';
import { toast } from 'react-toastify';

export default async function Logout() {

  const tokens = localStorage.getItem('Authorization');
  let objcet = JSON.parse(tokens);
  let access_token = objcet.access_token

  function successmsg(message) {
    toast.success(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left"
    });
  }

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left"
    });
  }

  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
      'Authorization': `Bearer ${access_token}`
    }
  };



  return await fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/logout`, requestOptions)
    .then(function (response) {
      if (response.status === 401) {
        Access_token();
      }
      return response.json();
    })
    .then(data => {
      if ('success' in data) {
        successmsg(data.success);
      } else {
        errorsmsg(data.errors);
      }
    })
    .catch(error => {
      errorsmsg(error);
    })
}


