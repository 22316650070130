import React from 'react'
import { Link } from 'react-router-dom';
import { RiWallet3Fill } from 'react-icons/ri';
import OrderVerify from '../api/Order_verify';

function DashboardWallet({ data, balance }) {

  const routeChange = () =>{ 
    OrderVerify();
  }

  return (
    <div className='form text-center w100 '>
      <div className='wallet--icon'>
        <RiWallet3Fill className="h1" /></div>
      <div className='h1 fw400 ccyan-100'>₹ <span>{balance}</span></div>
      <p className='user'>Hi ,
        {data.name}
      </p>
      {data.status === 'active' ? (
        <div className=''>
          <p className='ccyan-100 fw500'><i className='ico ico-tick'></i> Your Account is Activated</p>
          <p className='pt1 pb3'>You can place gift card orders here</p>
        </div>
      ) : data.status === 'pending' ? (
        <p className='message '>Your Account is under verification <br /> our employee will call or send notification</p>
      ): data.status === 'suspended' ? (
      <p className='message cred'>Your Account is suspended</p>)
      :('')}


      <div className='btn-group'>
        {data.status === 'active' ? (<button className='btn btn-pink  ' onClick={routeChange}><span className='ico ico-add '></span> NEW ORDER</button>):(<button className='btn btn-pink  ' disabled><span className='ico ico-add '></span> NEW ORDER</button>)}
        <Link to='/setting' className='btn btn-outline-gray ml3'><span className='ico ico-settings  '></span> SETTINGS</Link>
      </div>
    </div>
  )
}

export default DashboardWallet