//react main library
import React from 'react'
import Access_token from '../api/Access_token';
import PartnerDetails from '../api/PartnerDetails';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/setting/setting.scss'
import { RiArrowLeftLine } from 'react-icons/ri';

function Setting() {

  const navigate = useNavigate();

  const user_details = JSON.parse(atob(localStorage.getItem('user_details')))

  function successmsg(message) {
    toast.success(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false
    });
  }

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false,
      onClose: () => {
        window.location.reload();
      }
    });
  }

  const { register, handleSubmit, formState: { errors, isSubmitted, isSubmitSuccessful } } = useForm({
    mode: "onBlur"
  });

  const onSubmit = (data, e) => {

    const tokens = localStorage.getItem('Authorization');
    let objcet = JSON.parse(tokens);
    let access_token = objcet.access_token

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`
      },
      body: JSON.stringify({
        name: data.name,
        poc_name: data.user_name,
        email: data.email_address,
        poc_mobile: data.phone_number,
        address: data.address,
        gstn: data.gstno,
        pan_card_number: data.panno
      })
    };

    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners`, requestOptions)
      .then(function (response) {
        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(data => {
        if ('partner' in data) {

          // success message
          // successmsg();
          let redirect_url = '';
          let data_status ='';
          successmsg('Partner Details Update Successfully')
          PartnerDetails(access_token,redirect_url,data_status);
        } else {
          //error message
          errorsmsg(data.errors);
        }
      })
      .catch(error => {
        //error message
        errorsmsg(error);
      })

    // form reset
    e.target.reset();
  };

  return (
    <main>
      <Helmet>
        <title>Setting | Zingoy Partner Portal</title>
      </Helmet>
      <div className='mt5 w40 m-auto'>
        <div className='h4 fw600 mt5 mb5  setting_title' style={{verticalAlign: 'textBottom'}}>
          <div className=' cursor-hand' onClick={() => navigate(-1)}>
            <RiArrowLeftLine size='20px' />
            <span className='ml5 va-tb'>Settings</span>
          </div>
        </div>
        <form className='form regis__form--border_top' onSubmit={handleSubmit(onSubmit)}>
          <div className='registration__form--title'>Profile Update</div>
          <div>
            <div className='form-group'>
              <label htmlFor='name'>Company Name</label>
              <input type="text" defaultValue={user_details.name} name="name" {...register("name", {
                required: 'company name is required',
                pattern: {
                  value: /^[A-Za-z& ]+$/i,
                  message: "invalid company name"
                },
                minLength: {
                  value: 5,
                  message: 'company name at list more then 5 alphabet'
                }
              })} placeholder="Company Name" className='form-control' readOnly />
              <p className='cdanger h6'>{errors.name && <span>{errors.name.message}</span>}</p>
            </div>
            <div className='form-group'>
            <label htmlFor='last_name'>User Name</label>
            <input type="text" defaultValue={user_details.poc_name} name="user_name"  {...register("user_name",
              {
                required: 'user name is required',
                pattern: {
                  value: /^[A-Za-z ]+$/i,
                  message: "invalid user name"
                },
                minLength: {
                  value: 3,
                  message: 'user name at list more then 3 alphabet'
                }
              }
            )} placeholder="User Name" className='form-control' />
            <p className='cdanger h6'>{errors.user_name && <span>{errors.user_name.message}</span>}</p>
          </div>
            <div className='form-group'>
              <label htmlFor='email_address'>Email Address</label>
              <input type="email" defaultValue={user_details.poc_email} name="email_address"
                {...register("email_address",
                  {
                    required: 'email address is required',
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "invalid email address"
                    },
                    minLength: {
                      value: 3,
                      message: 'invalid email address'
                    }
                  }
                )} placeholder="Email Address" readOnly className='form-control' />
              <p className='cdanger h6'>{errors.email_address && <span>{errors.email_address.message}</span>}</p>
            </div>
            <div className='form-group'>
              <label htmlFor='phone_number'>Phone Number</label>
              <input type="number" defaultValue={user_details.poc_mobile}
                {...register("phone_number",
                  {
                    required: 'phone number is required',
                    pattern: {
                      value: /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/,// eslint-disable-line
                      message: "invalid mobile number"
                    }
                  }
                )} name="phone_number" readOnly onWheel={ event => event.currentTarget.blur() } placeholder="Phone Number" className='form-control' />
              <p className='cdanger h6'>{errors.phone_number && <span>{errors.phone_number.message}</span>}</p>
            </div>
            <div className='form-group'>
              <label htmlFor='address'>Address</label>
              <textarea rows="10" defaultValue={user_details.address} name="address" {...register("address", {
                required: 'address is required',
                minLength: {
                  value: 10,
                  message: 'address at list more then 3 alphabet'
                }
              })} placeholder="Address" className='form-control' readOnly></textarea>
              <p className='cdanger h6'>{errors.address && <span>{errors.address.message}</span>}</p>
            </div>
            <div className='form-group'>
              <label htmlFor='gstno'>GST Number</label>
              <input type="text" defaultValue={user_details.gstn} name="gstno" {...register("gstno", {
                required: 'GST number is required',
                pattern: {
                  value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                  message: "invalid GST number"
                }
              })} placeholder="GST Number" className='form-control' readOnly />
              <p className='cdanger h6'>{errors.gstno && <span>{errors.gstno.message}</span>}</p>
            </div>
            <div className='form-group'>
              <label htmlFor='gstno'>PAN Number</label>
              <input type="text" defaultValue={user_details.pan_card_number} name="panno" {...register("panno", {
                required: 'PAN Number is required',
                pattern: {
                  value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                  message: "invalid PAN Number"
                }
              })} placeholder="PAN Number" className='form-control' readOnly />
              <p className='cdanger h6'>{errors.panno && <span>{errors.panno.message}</span>}</p>
            </div>
            <div>
              <button type="submit" className='btn btn-pink btn__full--width' disabled={isSubmitted && isSubmitSuccessful}>{isSubmitted && isSubmitSuccessful ? "Updating..." : "UPDATE NOW"}</button>
            </div>
          </div>
        </form>
      </div>
    </main>
  )
}

export default Setting
