export default async function Access_token() {

  let objcet = JSON.parse(localStorage.getItem('Authorization'));
  let refresh_token = objcet.refresh_token

  return await fetch(`${process.env.REACT_APP_DEV_MODE}/oauth/token`, {
    method: 'POST',
    body: JSON.stringify({
      grant_type: `${process.env.REACT_APP_DEV_MODE_GRANT_TYPE}`,
      refresh_token: `${refresh_token}`
    }),
    headers: {
      "Content-type": "application/json",
      "Accept": "application/json",
      'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`
    }
  })
    .then(response => response.json())
    .then(data => {
      if ('refresh_token' in data) {
        window.localStorage.setItem('Authorization', JSON.stringify({
          access_token: `${data.access_token}`,
          refresh_token: `${data.refresh_token}`
        }));
      }
    })
    .catch(error => {

    })
}