import React from 'react'
import OTPVerificationCard from '../components/OTPVerificationCard'
import { Helmet } from 'react-helmet';
import '../assets/css/otp_verification/otpverification.scss'

function OTPVerification() {

  return (
    <main>
      <Helmet>
        <title>OTP Verification | Zingoy Partner Portal</title>
      </Helmet>
      <div className='Container'>
        <div className='center-grid height-full'>
          <OTPVerificationCard />
        </div>
      </div>
    </main>
  )
}

export default OTPVerification