import Access_token from "./Access_token";

export default async function Stores() {

  let objcet = JSON.parse(localStorage.getItem('Authorization'));
  let access_token = objcet.access_token

  return await fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/dashboard`, {
    method: 'GET',
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
      "Authorization": `Bearer ${access_token}`
    }
  })
    .then(function (response) {
      if (response.status === 401) {
        Access_token();
      }
      return response.json();
    })
}