import Access_token from "./Access_token";

export default async function ResendOtp() {


    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`
        },
        body: JSON.stringify({
          email: localStorage.getItem('email'),
        })
      };

  return await fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/send_otp`, requestOptions)
    .then(function (response) {
      if (response.status === 401) {
        Access_token();
      }
      return response.json();
    })
}