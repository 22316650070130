import { toast } from 'react-toastify';
import Access_token from './Access_token';
import 'react-toastify/dist/ReactToastify.css';

export default async function Additional_verification(access_token) {
  function successmsg(message) {
    toast.success(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false,
    });
  }

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false,
    });
  }

  return await fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/additional_verification`, {
    method: 'POST',
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
      "Authorization": `Bearer ${access_token}`
    }
  })
    .then(function (response) {
      if (response.status === 401) {
        Access_token();
      }
      return response.json();
    })
    .then(data => {
      if (!'msg' in data) {
        errorsmsg(data.errors);
      }
    })
    .catch(error => {
      errorsmsg(error);
    })
}