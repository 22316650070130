import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { AppBar } from "../../Layouts/Header/Navbar";

export const ProtectedLayout = () => {

  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <AppBar
        pages={[
          { label: "Dashboard", path: "dashboard" },
          { label: "Settings", path: "setting" }
        ]}
      />
      {outlet}
    </div>
  )
}
