import Access_token from "./Access_token";

export default async function ResendOtp() {

    const tokens = localStorage.getItem('Authorization');
    let objcet = JSON.parse(tokens);
    let access_token = objcet.access_token
    
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
          'Authorization': `Bearer ${access_token}`
        }
      };

  return await fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/additional_verification`, requestOptions)
    .then(function (response) {
      if (response.status === 401) {
        Access_token();
      }
      return response.json();
    })
}