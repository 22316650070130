import React, { useState,useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAuth } from "../hooks/useAuth";
import PartnerDetails from '../api/PartnerDetails';
import Access_token from '../api/Access_token';
import 'react-toastify/dist/ReactToastify.css';
import ResendOtp from '../api/ResendEmailOtp';

function Partner_Login() {

  const [getCounterTime,setCounter] = useState('01:00');
  const [displayTimer,setTimer] = useState(true);

  const { login } = useAuth();

  const { register, handleSubmit, formState: { errors, isSubmitted, isSubmitSuccessful } } = useForm({
    mode: "onChange"
  });

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false,
      onClose: () => {
        window.location.reload();
      }
    });
  }



  const startTimer = (duration) => {
    var timer = duration, minutes, seconds;
    var settime = setInterval(function () {
      minutes = parseInt(timer / 60, 10); // get minute
      seconds = parseInt(timer % 60, 10); // get second
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setCounter(minutes + ":" + seconds);

      if (--timer < 0) {
        clearInterval(settime)
        timer = 0;
        setTimer(false);
      }
    }, 1000);

  }
  
  useEffect(() => {


    if (displayTimer) {
      startTimer(60);
    }
  }, [])

  const onSubmit = (data, e) => {

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`
      },
      body: JSON.stringify({
        otp: data.email_otp,
        email: localStorage.getItem('email')
      })
    };

    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/login`, requestOptions)
      .then(function (response) {
        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(data => {
        if ('success' in data) {
            var redirect_url = '/dashboard'
            let data_status ='not_empty';
           //Partner Details saving
           PartnerDetails(data.access_token,redirect_url,data_status);
          //login access token set
          login({
            access_token: `${data.access_token}`,
            refresh_token: `${data.refresh_token}`
          });
        } else {
          //error message
          errorsmsg(data.errors);
        }
      })
      .catch(error => {
        //error message
        errorsmsg(error);
      })

    // form reset
    e.target.reset();
  };

  const emailhide = (email) => {
    //split email name & domain name
   let email_split = email.split("@");
   // take email name from split_email 
   let email_name = email_split[0];
   let empty_string = "";
    for(let index in email_name){
      if (index>1 && index<email_name.length - 1) empty_string += '*';
      else empty_string += email_name[index];
    }
    return empty_string + '@' + email_split[1];
  };

  return (
    <div className='gap'>
    <div className='form'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className='otp--title'>Enter One Time Password</h1>
        <p className='text-center h5'>OTP is Send to Your Email</p>
        <p className='text-center bold h5'>{emailhide(localStorage.getItem('email'))}</p> 
        <div className='text-center  mt2'>
                        {displayTimer ? getCounterTime : <div  className="cpink cursor-hand" onClick={()=>{setTimer(true);startTimer(60);ResendOtp();}}>RESEND</div> }
                        </div>
        <div className='form-group mt5'>
          <input type="text" name="email_otp" className='form-control input__text--style'
            {...register("email_otp",
              {
                required: 'email otp is required',
                minLength: {
                  value: 6,
                  message: 'email otp length 6 digit'
                }
              }
            )}
          />
          <p className='cdanger h6'>{errors.email_otp && <span>{errors.email_otp.message}</span>}</p>
        </div>
        <button className='btn btn-pink btn__full--width' type='submit' disabled={isSubmitted && isSubmitSuccessful}>{isSubmitted && isSubmitSuccessful ? "Loading..." : "VERIFY NOW"}</button>
      </form>
    </div>
  </div>
  )
}

export default Partner_Login