import Access_token from './Access_token';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default async function PartnerDetails(access_token,redirect_url,data_status) {

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left"
    });
  }

 

  return await fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/me`, {
    method: 'GET',
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
      "Authorization": `Bearer ${access_token}`
    }
  })
    .then(function (response) {
      if (response.status === 401) {
        Access_token();
      }
      return response.json();
    })
    .then(data => {
      if ('current_partner' in data) {
        new Promise((resolve, reject) => {
          setTimeout(() => resolve("value"), 2000);
        })
          .finally(() => {window.localStorage.setItem('user_details', btoa(JSON.stringify(data.current_partner)))
          data_status === ''?window.location.reload() : window.location.href = redirect_url;
        }) // triggers first
      }
      else {
        errorsmsg(data.errors);
      }
    })
    .catch(error => {
      errorsmsg(error);
    })
}