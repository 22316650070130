import React from 'react'

function RegistrationCard(data) {
  return (
    <div>
      <h1 className='registration__info--title'>Zingoy Partner Portal</h1>
      <p className='registration__info--subtitle'>{data.subtitle} </p>
      <img src='https://res-1.cloudinary.com/linkisin/image/upload/v1649225281/zingoy/Merchant_Portal/Zingoy-Merchant-Portal.png' alt='registration-lgo' loading='lazy' />
    </div>
  )
}

export default RegistrationCard