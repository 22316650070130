import React from 'react'
import { Helmet } from 'react-helmet';
import RegistrationForm from '../components/RegistrationForm'
import RegistrationCard from '../components/RegistrationCard'
import '../assets/css/registration/registration.scss'

function Registration() {


  return (
    <main>
      <div className='Container'>
        <Helmet>
          <title>Registration | Zingoy Partner Portal</title>
        </Helmet>
        <div className='grid height-full'>
          <div className='text-center center-grid'>
            <RegistrationCard subtitle="register with us for your requirment" />
          </div>

          <div  className='center-grid'>
            <RegistrationForm />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Registration