import React, { useEffect, useState } from 'react'
import GiftCards from '../components/GiftCards';
import DashboardWallet from '../components/DashboardWallet';
import YourOrder from '../components/YourOrder';
import StoreApi from '../api/Stores.js';
import { Helmet } from 'react-helmet';
import '../assets/css/dashboard/dashbaord.scss'
function Dashboard() {
  //set clssname to body
  document.body.classList.add('body');

  const [list, setList] = useState([]);
  const [getPartner, setPartner] = useState('');
  const [getBlance, setBlance] = useState('0.0');

  useEffect(() => {

    let mounted = true;
    StoreApi()
      .then(items => {
        if (mounted) {
          setList(items.partner_orders)
          setPartner(items.partner)
          setBlance(items.partner_balance)
        }
      })
    return () => mounted = false;
  }, [])


  return (
    <main>
      <Helmet>
        <title>Dashboard | Zingoy Partner Portal</title>
      </Helmet>
      <div className="Container">
        <div className='text-center h3 fw600 pt5 mb3 w100'>Zingoy Business</div>
        <br />
        <div className='w80 m-auto'>
          <div className="mt5">
            <div className='w50 m-auto'>
              <DashboardWallet data={getPartner} balance={getBlance} />
            </div>
            <div className='pt5'>
              <YourOrder data={list} />
            </div>

          </div>
        </div>
      </div>
      <div className='swiperlr'>
            <GiftCards />
      </div>
    </main>
  )
}

export default Dashboard
