import React, { useState } from 'react'
import Access_token from '../api/Access_token';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';
import { RiArrowLeftLine } from "react-icons/ri";
import { TbCurrencyRupee } from "react-icons/tb";
import { AiOutlineArrowRight } from "react-icons/ai";


function YourOrder({ data }) {

  let objcet = JSON.parse(localStorage.getItem('Authorization'));
  let access_token = objcet.access_token

  const [isData, setDataDisplay] = useState(false);
  const [list, setList] = useState([]);
  const [listProduct, setProduct] = useState([]);

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false,
      onClose: () => {
        window.location.reload();
      }
    });
  }

  function OrderDetails(orderid) {
    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/orders/status?zingoy_order_id=${orderid}`, {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
        "Authorization": `Bearer ${access_token}`
      }
    })
      .then(function (response) {
        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(data => {
        if ('order_status' in data) {
          setList(data)
          setProduct(data.products)
        }
        else {
          errorsmsg(data.errors);
        }
      })
      .catch(error => {
        errorsmsg(error);
      })
  }


  if (data.length === 0) {
    return ""
  }

  return (
    <div >
      <div className='text-center h4 fw600 pt5 w100' >
        <span className={isData ? 'd-none' : ''}>Your Order </span>
      </div>
      <div className={isData ? 'd-none' : ''}>
        {
          data.map((item,index) => (
            <div style={{marginTop: index === 0 ? '0.25rem': ''}}  className='w100 bgwhite order--status border-4'  key={item.id}>
              <div>
                <div className='p2 pl4 fw500 h5'>Order Number</div>
                <div className='pt4 pl4  store border-top-sperator'>{item.id}</div>
              </div>
              <div>
                <div className='p2 fw500 h5'>Order Date</div>
                <div className='p2 pt4 store border-top-sperator'>{item.order_date}</div>
              </div>
              <div>
                <div className='p2 fw500 h5'>Numbers Of Stores</div>
                <div className='pl2 pt4  store border-top-sperator'>{item.num_of_stores}</div>
              </div>
              <div>
                <div className='p2 fw500 h5'>Status</div>
                <div className='p2 pt4 store border-top-sperator'>
                  <ReactTooltip id={`registerTip-${item.id}`} place="top" effect="solid" data-border="true" data-multiline="true" textColor="#000" backgroundColor="#fff" borderColor="#ccc" className="tooltip-shadow" html={true}>
                  {item.status_description}
                  </ReactTooltip>
                  <span className={`badge badge-pill ${item.status === 'Success' ? 'badge-success' : item.status === 'Pending' ? 'badge-pending' : item.status === 'In Progress' ? 'badge-in-progress' : ''}`} data-tip data-for={`registerTip-${item.id}`}>
                    {item.status}
                  </span>
                </div>
              </div>
              <div>
                <div className='p2 fw500 h5'>Total Value</div>
                <div className='p1 pt3  store border-top-sperator ccyan-100 fw500 h2'><span style={{verticalAlign: 'middle'}} className="rupeessymbol" ><TbCurrencyRupee size='24px'  /></span>{item.amount}</div>
              </div>
              <div className='btn-details'>
                <div className='p2 fw500 h5 m-none'>&nbsp;</div>
                <div className='p2 store border-top-sperator'><button type='button' className='btn btn-pink btn-small ' id={item.id} onClick={() => {
                  OrderDetails(`${item.id}`)
                  setDataDisplay(true)
                }
                }>ORDER DETAILS <AiOutlineArrowRight size={18} style={{marginBottom:'-3px',marginLeft:'5px'}} /></button></div>
              </div>
            </div>
          ))
        }
      </div>
      <div>
        {
          isData ? (
            <div>
              <div className='text-center h4 fw500  pb2 w100'>
                <div className='setting_title cursor-hand' onClick={() => setDataDisplay(false)}>
                  <RiArrowLeftLine size='20px' className='h2' />
                  <span className='ml2 va-tb'>
                    Your Orders Details
                  </span>
                </div>
              </div>
              <div className='h5 fw500 bgwhite d-flex mb5  p3 border-4'>
                <div>Order Number : #</div>
                <div className='cpink bold'>{list.zingoy_order_id}</div>
              </div>
              {
                listProduct.map((data) => (
                  <div className='bgwhite border-4 order--details__shadow' key={data.product_id}>
                    <div className='text-align p2 h4 fw500'>{data.name}</div>
                    <div className='w100 order--details__status'>
                      <div>
                        <div className='p2 pl4 fw500 h5'>Order Date</div>
                        <div className='p2 pl4 store'>{data.order_date}</div>
                      </div>
                      <div>
                        <div className='p2  fw500 h5'>Denomination</div>
                        <div className='p2 store'>{data.denomination}</div>
                      </div>
                      <div>
                        <div className='p2  fw500 h5'>Numbers Of Giftcards</div>
                        <div className='p2 store'>{data.quantity}</div>
                      </div>
                      <div>
                        <div className='p2  fw500 h5'>Status</div>
                        <div className='p2 store'>
                          <ReactTooltip id="registerTip" place="top" effect="solid" data-border="true" data-multiline="true" textColor="#000" backgroundColor="#fff" borderColor="#ccc" className="tooltip-shadow">
                          {data.status_description === 'success' ? 'Gift Cards dispatched' : data.status === 'Pending' ? 'We Have Not Received Your Payment. Please contact <a href="mailto:sales@zingoy.com">sales@zingoy.com</a> for more information' : data.status === 'in_progress' ? 'We have received your payment. Gift cards will be dispatched within 48 Hrs' : ''}
                          </ReactTooltip>
                          <span className={`badge badge-pill ${data.status === 'success' ? 'badge-success' : data.status === 'Pending' ? 'badge-pending' : data.status === 'in_progress' ? 'badge-in-progress' : ''}`} data-tip data-for="registerTip">
                            {data.status}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className='p2  fw500 h5'>Total Value</div>
                        <div className='p1   store  ccyan-100 fw500 h2'><span className="rupeessymbolt"><TbCurrencyRupee size={23} style={{marginBottom:'-3px',marginLeft:'5px'}}  /></span>{data.total_amount}</div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : ('')
        }
      </div>
    </div>

  )
}

export default YourOrder