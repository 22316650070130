import React,{useState} from 'react'
import RegistrationCard from '../components/RegistrationCard'
import Access_token from '../api/Access_token';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import '../assets/css/login/login.scss'
import 'react-toastify/dist/ReactToastify.css';

function Login() {

  const [getError,setError] = useState('');
  const [getLoading,setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur"
  });

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false,
      onClose: () => {
        window.location.reload();
      }
    });
  }

  const onSubmit = (data, e) => {
    setLoading(true)
    localStorage.setItem("email", data.email_address);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`
      },
      body: JSON.stringify({
        email: data.email_address,
      })
    };
    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/partners/send_otp`, requestOptions)
      .then(function (response) {
        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(data => {
        if ('success' in data) {
          setError('')
          window.location.href = '/partnerlogin';
        } else {
          setError(data.errors)
        }
      })
      .catch(error => {
        errorsmsg(error);
      })
      .finally(()=>{
        setLoading(false)
      })
    // form reset
    e.target.reset();
  };


  return (
    <main>
      <Helmet>
        <title>Login | Zingoy Partner Portal</title>
      </Helmet>
      <div className='Container'>
        <div className='grid height-full'>
          <div className='text-center center-grid'>
            <RegistrationCard subtitle="Login and start ordering" />
          </div>
          <div className='center-grid'>
            <div className='w70 m-auto'>
              <form className='form login__form--border_top mt5' onSubmit={handleSubmit(onSubmit)}>
                <div className='login__form--title'>Login</div>
                <div>
                  <div className='form-group'>
                    <label htmlFor='email_address'>Email Address</label>
                    <input type="email" name="email_address"
                      {...register("email_address",
                        {
                          required: 'email address is required',
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "invalid email address"
                          }
                        }
                      )}
                      placeholder="Email Address" className='form-control' />
                    <p className='cdanger h6'>{errors.email_address && <span>{errors.email_address.message}</span>||<span>{getError}</span>}</p>
                  </div>

                  <div>
                    <button type="submit" className='btn btn-pink btn__full--width' disabled={getLoading}>{getLoading ? "Loading..." : "LOGIN NOW"}</button>
                  </div>
                  <p className='text-center mt2 mb1 bold'>OR</p>
          <p className='text-center'>To Register a new Partner <a href='/registration'>click here</a></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Login