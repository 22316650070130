import Access_token from './Access_token';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default async function Order_verify() {

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left"
    });
  }
  const tokens = localStorage.getItem('Authorization');
  let objcet = JSON.parse(tokens);
  let access_token = objcet.access_token

  return await fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/orders/new`, {
    method: 'GET',
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
      "Authorization": `Bearer ${access_token}`
    }
  })
    .then(function (response) {
      if (response.status === 401) {
        Access_token();
      }
      return response.json();
    })
    .then(data => {
      if ('success' in data) {
        if (data.mobile_verified === false) {
            window.location.href = '/mobileverification';
        } 
      } else if ('brands' in data) {
        window.location.href = '/neworders';
      }else{
        errorsmsg(data.errors)
      }
    })
    .catch(error => {
      errorsmsg(error);
    })
}