import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate, NavLink } from "react-router-dom";
import "../../assets/css/Navbar/navbar.scss";

export const AppBar = ({ pages }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const buttonRef = useRef(null);

  useEffect(() => {
    if (isNavExpanded) {
      document.body.style.cssText =
        "overflow:hidden;";

      document.body.addEventListener("click", onClickOutside);

    } else {
      document.body.style.cssText = "overflow:auto;";
    }
    return () => document.removeEventListener("click", onClickOutside);
  }, [isNavExpanded]);


  const handleCloseNavMenu = (path) => {
    if (path) {
      navigate(path);
    }
  };

  const onClickOutside = (e) => {
    const element = e.target;
    if (buttonRef.current.contains(element)) {
      setIsNavExpanded(!isNavExpanded);
    }
  };

  const onClickMside = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <nav className="navigation">
      <div className="brand-name" >
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <a href={localStorage.getItem('Authorization') === null ? '/' : '/dashboard'}>
          <img loading="lazy"
            src="https://res-1.cloudinary.com/linkisin/image/upload/v1652255572/zingoy/merchant-portal/Zingoy-Merchanthub-logo.png"
            alt="zingoy logo"
          />
        </a>
      </div>
      <div className={isNavExpanded ? "mobile--bg" : ""} ref={buttonRef}></div>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        {isNavExpanded ?
          <span className="close-menu" onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}>
            <svg class="svg-icon" viewBox="0 0 20 20" className="h-5 w-5">
              <path
                fill="white"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
              ></path>
            </svg>
          </span>
          : ""}
        <div className="nav--links">
          {
            isNavExpanded ?
              <>
                <a href="/" className="bgblue mobile__logo--section">
                  <img loading="lazy"
                    src="https://res-1.cloudinary.com/linkisin/image/upload/v1652255572/zingoy/merchant-portal/Zingoy-Merchanthub-logo.png"
                    className="mobile__nav--img"
                    alt="zingoy logo"
                  />
                </a>
                {pages.map((page, index) => (
                  <NavLink
                    key={index}
                    onClick={(e) => { handleCloseNavMenu(page.path); onClickMside() }}
                    className={({ isActive }) => (isActive ? `active` : ``)}
                    to={page.path}
                    // exact="true"
                    end>
                    {page.label}
                  </NavLink>
                ))}
                {!!user && (
                  <a href="/" key={"logout"} onClick={logout}>Logout</a>
                )}
              </>
              :
              <>
                {pages.map((page, index) => (
                  <NavLink
                    key={index}
                    onClick={(e) => { handleCloseNavMenu(page.path) }}
                    className={({ isActive }) => (isActive ? `active` : ``)}
                    to={page.path}
                    // exact="true"
                    end>
                    {page.label}
                  </NavLink>
                ))}
                {!!user && (
                  <a href="/" key={"logout"} onClick={logout}>Logout</a>
                )}
              </>
          }
        </div>
      </div>
    </nav>
  );
}

