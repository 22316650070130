import React, { useEffect, useState } from 'react'
import AllBrands from '../api/All_brands'
import Modal from '../components/LoadMoneyModal'
import Access_token from '../api/Access_token'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../assets/css/new_order/new_order.scss'
import 'react-toastify/dist/ReactToastify.css';
import { RiArrowLeftLine } from "react-icons/ri";
import { IoRefresh, IoAddCircleSharp } from "react-icons/io5";
import { TbCurrencyRupee } from "react-icons/tb";
import { MdDelete } from "react-icons/md";




function Orders({ order, index, removeOrder }) {
  function orderdelete(orderid) {
    toast.error(`Order ${orderid} Delete`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false
    });
  }

  return (
    <div>
      {
        <div className="form order mt5" key={index}>
          <div className='brand'>
            <label htmlFor="brand" className="h5 fw600">Brand</label>
            <div>{order.brand}</div>
          </div>
          <div className='numberofgc'>
            <label htmlFor="number_of_giftcards" className="h5 fw600">Number Of Giftcards</label>
            <div className="form-control-none">{order.quantity}</div>
          </div>
          <div className='denomination'>
            <label htmlFor="denomination" className="h5 fw600">Denomination</label>
            <div className="p-relative customTextfield">
              <div className="form-control-none rupees">{order.amount}</div>
            </div>
          </div>
          <div className='btns'>
            <div className=" mt5 group-btn ">
              <button type="button" className="btn btn-pink btn__round" id={index} onClick={
                () => { removeOrder(index); orderdelete(order.product_id) }
              }>
                <MdDelete  size={16}/></button></div>
          </div>
        </div>
      }
    </div>
  )
}


function OrderForm({ addOrder }) {
  //set clssname to body
  document.body.classList.add('body');
  // api gift card data
  const [list, setList] = useState([]);

  const [showDropdown, setDropdownStatus] = useState(false);

  const [showProductList, setProductList] = useState([]);

  useEffect(() => {

    AllBrands()
      .then(items => {
        setList(items.brands)
      })
  }, [])

  function products_res(brandid) {
    const tokens = localStorage.getItem('Authorization');
    let objcet = JSON.parse(tokens);
    let access_token = objcet.access_token

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
        'Authorization': `Bearer ${access_token}`
      }
    };

    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/brands/${brandid}/products`, requestOptions)
      .then(function (response) {

        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(response => {
        const res_data = response.products;
        if (res_data.length === 0) {
          setDropdownStatus(false);
        } else {
          setProductList(res_data);
          setDropdownStatus(true);
        }
      })

  }




  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    mode: "onSubmit"
  }
  );

  const onSubmit = (data, e) => {

    toast.success(`Order ${data.product_id} Created`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false
    });
    addOrder(data);
    reset();
  }

  const selected_brand = (e) => {
    products_res(e.target.options[e.target.selectedIndex].dataset.brandid)
  }

  const selected_product = (e) => {
    setValue('product_id', e.target.options[e.target.selectedIndex].dataset.pid);
    setValue('amount', e.target.options[e.target.selectedIndex].dataset.prodenom);
  }

  return (
    <form className='form new_order' onSubmit={handleSubmit(onSubmit)}>
      <div className='pl5 pr5'>
        <div className='form-group'>
          <label htmlFor="numberOfGC">Brand</label>
          <select className='form-control'
            name='brand'
            {...register("brand",
              {
                required: 'please select brand name',
              }
            )}
            onChange={(e) => selected_brand(e)}
          >
            <option value=''>Please Select Brand</option>
            {
              list.map((brand_data) => (
                <option
                  key={brand_data.brand_id}
                  data-brandid={brand_data.brand_id}
                  value={brand_data.brand_name}>
                  {brand_data.brand_name}</option>
              ))
            }
          </select>
        </div>
        <p className='cdanger h6'>{errors.brand && <span>{errors.brand.message}</span>}</p>
        <div className='form-group'>
          {
            showDropdown ? (
              <div>
                <label htmlFor="numberOfGC">Products</label>
                <select className='form-control'
                  defaultValue={{ label: "Choose one", value: "" }}
                  name='product'
                  {...register("product",
                    {
                      required: 'please select brand name',
                    }
                  )}
                  onChange={(e) => selected_product(e)}
                >
                  <option value=''>Please Select Product</option>
                  {
                    showProductList.map((prod_data) => (
                      <option key={prod_data.product_id}
                        data-prodenom={prod_data.denomination}
                        data-pid={prod_data.product_id}
                        value={prod_data.product_name}
                      >
                        {prod_data.product_name +' - '+'₹'+prod_data.denomination }</option>
                    ))
                  }
                </select>
                <p className='cdanger h6'>{errors.product && <span>{errors.product.message}</span>}</p>
              </div>
            ) : ('')
          }
        </div>
        <div className='form-group'>
          <label htmlFor="numberOfGC">Number Of Giftcards</label>
          <input type="number" name="numberOfGC" onWheel={ event => event.currentTarget.blur() } placeholder='Number Of Giftcards' className='form-control'
            {...register("quantity",
              {
                required: 'total number of gift card details required',
              }
            )}
          />
        </div>
        <p className='cdanger h6'>{errors.quantity && <span>{errors.quantity.message}</span>}</p>
        <div className='form-group'>
          <button type="submit" className='btn btn-pink  btn__full--width'>
            <div className='d-inline-flex'>   <span>ADD SET</span>
              <IoAddCircleSharp className='ml3' size='20px' /></div>
          </button>
        </div>
      </div>
    </form>

  )
}

function NewOrders() {

  const [getorder, setNewOrder] = useState([]);
  const [show, setShow] = useState(false);
  const [getOrderValue, setOrderTotalValue] = useState(null);
  const navigate = useNavigate();

  useEffect(()=>{
    const orders = getorder.map( params => params.quantity * params.amount);

    const initialValue = 0;

    const order_details = orders.reduce((a, b) => a + b,initialValue);

      setOrderTotalValue(order_details);
  })

  const addOrder = (data) => {
    const newOrder = [...getorder, data];
    setNewOrder(newOrder);
  };

  const removeOrder = (index) => {
    const newOrder = [...getorder];
    newOrder.splice(index, 1);
    setNewOrder(newOrder);
  };

  function bulkorder() {

    function successmsg(message) {
      toast.success(`${message}`, {
        theme: "light",
        newestOnTop: true,
        position: "top-left"
      });
    }

    function errorsmsg(message) {
      toast.error(`${message}`, {
        theme: "light",
        newestOnTop: true,
        position: "top-left",
        pauseOnHover: false
      });
    }

    const user_details = JSON.parse(atob(localStorage.getItem('user_details')))
    let objcet = JSON.parse(localStorage.getItem('Authorization'));
    let access_token = objcet.access_token

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
        'Authorization': `Bearer ${access_token}`
      },
      body: JSON.stringify({
        delivery_mode: 'API',
        customer_email: `${user_details.poc_email}`,
        customer_name: `${user_details.name}`,
        customer_mobile: `${user_details.poc_mobile}`,
        order_details: getorder
      })
    };


    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/orders`, requestOptions)
      .then(function (response) {
        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(data => {
         if ('order_status' in data) {
          setShow(true);

        }
        else {
          errorsmsg(data.errors);
        }
      })
      .catch(error => {
        errorsmsg(error);
      })
  }

  const goto = () => window.location.href = "/dashboard";

  return (
    <main>
      <Helmet>
        <title>New Orders | Zingoy Partner Portal</title>
      </Helmet>
      <div className='container'>
        <div className='new_oreder-form m-auto'>
          <div className='fw600 h3 text-center pt5 pb5 cursor-hand ' onClick={() => navigate(-1)}>
            <RiArrowLeftLine className='mr5 ' size='20px' />
            <span>New Order</span>
          </div>
          <OrderForm addOrder={addOrder} />
        </div>


        <div className='new_oreder-form m-auto pt5'>
          <div>
            {
              getorder.map((order, index) => (
                <Orders
                  key={index}
                  index={index}
                  order={order}
                  removeOrder={removeOrder}
                />
              ))

            }
          </div>

          <div className='form mt5 mb5 amount-load'>
            <div className='order-value fw600 mt3'>
              <p>Total Order Value</p>
              <div className='ml5 ccyan-100 d-flex order-price'><div><TbCurrencyRupee size='25px' className='bold'  /></div><div className='order-price_amount'>{getOrderValue}</div></div>
            </div>
            <div className=' cblue'>
              <button type='button' className='btn btn-pink btn__full--width' onClick={() => {
                bulkorder();
              }}>
                <div className='info'>
                  <span>GENERATE ORDER</span>
                  <IoRefresh className='ml2' size='20px' />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal onClose={() => {
        setShow(false);
        goto();
        }} show={show} />
    </main>
  )
}



export default NewOrders