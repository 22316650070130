import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from "./useLocalStorage";
import Logout from '../api/Logout';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useLocalStorage("Authorization", null);
  const navigate = useNavigate();

  const login = async (data) => {
    setUser(data);
    // navigate("/otpverification", {replace:true});
  }
  const logout = () => {
    Logout();
    setUser(null);
    window.localStorage.clear();
    navigate("/", { replace: true });
  };



  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  )


  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
}
