import React,{useEffect,useState} from 'react'
import Registration from './pages/Registration';
import OTPVerification from './pages/OTPVerification';
import PartnerLogin from './pages/PartnerLogin';
import MobileVerification from './pages/MobileVerification';
import NewOrders from './pages/NewOrders';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Access_token from './api/Access_token';
import Setting from './pages/Setting';
import { ProtectedLayout } from './Layouts/Header/ProtectedLayout';
import { PublicLayout } from './Layouts/Header/PublicLayout';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './assets/css/font/font.css';
import './assets/css/base.scss';
import './assets/css/generic.scss';
import Logout from './api/Logout';

function App() {

  setInterval(() => {
    Access_token();
  }, 7080900);

  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
  let timeoutId;
  useEffect(() => {
    if (accessToken) {
      timeoutId = setTimeout(() => {
        // Log the user out
        setAccessToken(false);
        var tokens = localStorage.getItem('Authorization');
        var objcet = JSON.parse(tokens);
        var access_token = objcet.access_token
        Logout(access_token)
        window.localStorage.clear();
        window.location = '/'
      }, 30 * 60 * 1000); // 1 minutes in milliseconds
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [accessToken]);

  const handleUserInteraction = ()=> {
    // Reset the timer
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // Log the user out
      setAccessToken(false);
      var tokens = localStorage.getItem('Authorization');
        var objcet = JSON.parse(tokens);
        var access_token = objcet.access_token
        Logout(access_token)
        window.localStorage.clear();
        window.location = '/'
    }, 30 * 60 * 1000); // 1 minutes in milliseconds
  }


  return (
    <div className="App" onMouseMove={handleUserInteraction} onClick={handleUserInteraction}>
      <Routes >
        <Route element={<PublicLayout />} >
          <Route path="/" element={<Login />} />
          <Route path="/partnerlogin" element={<PartnerLogin />} />
          <Route path="/registration" element={<Registration />} />
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
        <Route element={<ProtectedLayout />} >
          <Route path="/otpverification" element={<OTPVerification />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/neworders" element={<NewOrders />} />
          <Route path="/mobileverification" element={<MobileVerification />} />
          <Route path="/setting" element={<Setting />} />
        </Route>
      </Routes>
      {/* ToastContainer message */}
      <ToastContainer />
    </div>
  );
}

export default App;
