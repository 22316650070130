import React, { useState,useEffect } from 'react'
import ResendOtp from '../api/ResendOtp';
import Access_token from '../api/Access_token';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import '../assets/css/otp_verification/otpverification.scss'
import 'react-toastify/dist/ReactToastify.css';

function MobileVerification() {

  const [getCounterTime,setCounter] = useState('01:00');
  const [displayTimer,setTimer] = useState(true);


  const { register, handleSubmit, formState: { errors, isSubmitted, isSubmitSuccessful } } = useForm({
    mode: "onChange"
  });

  function errorsmsg(message) {
    toast.error(`${message}`, {
      theme: "light",
      newestOnTop: true,
      position: "top-left",
      pauseOnHover: false,
      onClose: () => {
        window.location.reload();
      }
    });
  }

  const onSubmit = (data, e) => {

    const tokens = localStorage.getItem('Authorization');
    let objcet = JSON.parse(tokens);
    let access_token = objcet.access_token

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Zingoy-Client': `${process.env.REACT_APP_DEV_MODE_X_ZINGOY_CLIENT}`,
        'Authorization': `Bearer ${access_token}`
      },
      body: JSON.stringify({
        otp: data.otp
      })
    };

    fetch(`${process.env.REACT_APP_DEV_MODE}/api/v1/verify-mobile-number/confirm`, requestOptions)
      .then(function (response) {
        if (response.status === 401) {
          Access_token();
        }
        return response.json();
      })
      .then(data => {
        if ('success' in data) {
          // success message
          window.location.href = '/neworders'

        } else {
          //error message
          errorsmsg(data.errors);
        }
      })
      .catch(error => {
        //error message
        errorsmsg(error);
      })

    // form reset
    e.target.reset();
  };

  const mobilenohide = (mobileno) => {
    let empty_string = "";
    for (let index in mobileno) {
      if (index > 2 && index < 9) empty_string += '*';
      else empty_string += mobileno[index];
    }
    return empty_string;
  };

  const startTimer = (duration) => {
    var timer = duration, minutes, seconds;
    var settime = setInterval(function () {
      minutes = parseInt(timer / 60, 10); // get minute
      seconds = parseInt(timer % 60, 10); // get second
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setCounter(minutes + ":" + seconds);

      if (--timer < 0) {
        clearInterval(settime)
        timer = 0;
        setTimer(false);
      }
    }, 1000);

  }
  
  useEffect(() => {


    if (displayTimer) {
      startTimer(60);
    }
  }, [])

  return (
    <main>
      <Helmet>
        <title>Mobile Verification | Zingoy Partner Portal</title>
      </Helmet>
      <div className='Container'>
        <div className='w50 m-auto'>
          <div className='gap'>
            <div className='form w70 m-auto '>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className='otp--title'>Enter One Time Password</h1>
                <p className='text-center h5'>OTP is Send to {mobilenohide(JSON.parse(atob(localStorage.getItem('user_details'))).poc_mobile)}</p>

                    <div className='text-center  mt2'>
                        {displayTimer ? getCounterTime : <div  className="cpink cursor-hand" onClick={()=>{setTimer(true);startTimer(60);ResendOtp();}}>RESEND</div> }
                        </div>

                <div className='form-group mt5'>

                  <input type="text" name="otp" className='form-control input__text--style'
                    {...register("otp",
                      {
                        required: 'otp is required'
                      }
                    )}
                  />
                  <p className='cdanger h6'>{errors.otp && <span>{errors.otp.message}</span>}</p>
                </div>
                <button className='btn btn-pink btn__full--width' type='submit' disabled={isSubmitted && isSubmitSuccessful}>{isSubmitted && isSubmitSuccessful ? "Loading..." : "VERIFY NOW"}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default MobileVerification