import React from 'react';

const LoadMoneyModal = (props) => {

  if (!props.show) {
    return null
  }


  return (
    <div className={`modal`} onClick={props.onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="success_animation">
          <svg className="animated-check" viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
          </svg>
        </div>
        <h4 className="modal-title mt2">
          Your Request sent to <br /> our verification department
        </h4>
        <div className="modal-body">it will take 24 hrs to get verified</div>
        <div className="modal-footer border-top">
          <button className="close--button" onClick={props.onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default LoadMoneyModal